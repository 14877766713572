
.freight-wrapper {
    height: 100%;

    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }

    .dialog-footer {
        text-align: center;
    }
}

.freight-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    .freight-info {
        padding: 0 20px;
        height: calc(100vh - 220px);

        .freight-title {
            font-size: 20px;
            padding-left: 15px;
            line-height: 1;
            margin: 20px 0 30px;
            position: relative;
            display: flex;
            align-items: center;
            /*justify-content: space-between;*/
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0;
                width: 5px;
                height: 20px;
                background: #2DC079;
                border-radius: 3px;
                transform: translateY(-50%);
            }
        }

        .item {
            margin-bottom: 16px;
            padding-left: 78px;

            .text {
                padding-right: 12px;
                display: inline-block;
                min-width: 82px;
            }

            .freight-box {
                border: 1px solid #eee;

                .freight-top {
                    background: #F6F6F6;
                    padding: 20px 47px;
                }

                .region-item {
                    display: flex;

                    span {
                        display: inline-block;
                        width: 40px;
                        color: #2461EF;
                        padding-left: 20px;
                        align-self: center;
                        cursor: pointer;
                    }
                }

                .add-freight {
                    cursor: pointer;
                    padding: 20px;
                    color: #2461EF;
                }
            }

            .freight-title {
                margin-bottom: 15px;
            }

            .material-upload-area {
                display: flex;
                align-items: center;

                .el-upload-tip {
                    color: #999;
                    padding-left: 10px;
                }

                ::v-deep .el-upload-list {
                    display: flex;
                    align-items: center;
                }
            }

            ::v-deep .el-upload-list {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            ::v-deep .el-upload-list__item {
                width: 300px;
                padding-left: 10px;
            }
        }
    }

    .pop-up {
        height:300px;

        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .area-box {
            display: flex;

            .area-list {
                display: flex;
                flex-wrap: wrap;
                padding-left: 45px;

                .checkbox-label {
                    width: 104px;
                    margin-bottom: 10px;

                    i {
                        cursor: pointer;
                    }
                }
            }

            .checkbox-group {
                margin: 10px 0;

                ::v-deep .el-checkbox-group {
                    display: flex;
                    flex-wrap: wrap;
                    /*width: 100px;*/
                    /*background-color: #fff;*/
                    /*box-shadow: 2px 2px 2px 3px #eee;*/
                    padding-left: 10px;
                }

                /*::v-deep .el-checkbox {*/
                /*    width: 58px;*/
                /*}*/
            }
        }
    }

}

